import { Heading, Text, VStack } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React from 'react';
import { CardGrid } from '../components/CardGrid';
import { Page } from '../components/Page';
import { seo } from '../components/Seo';
import { TopParagraphs } from '../components/TopParagraphs';

interface CardData {
  heading: string;
  body: string;
}
interface Props {
  data: {
    structureJson: {
      nzParagraphs: string[];
      committees: CardData[];
      ausrocParagraphs: string[];
      wiseraParagraphs: string[];
      widerIWW: {
        intro: string;
        items: CardData[];
      };
    };
  };
}

export const Head = seo({ title: 'Structure' });

export default function StructurePage({
  data: {
    structureJson: { nzParagraphs, wiseraParagraphs, ausrocParagraphs, widerIWW },
  },
}: Props) {
  return (
    <Page>
      <TopParagraphs title="Structure" subtitle="Aotearoa New Zealand" paragraphs={nzParagraphs} />

      <VStack spacing={4}>
        <Heading size="md">AusROC</Heading>
        {ausrocParagraphs.map((item: string) => (
          <Text key={item}>{item}</Text>
        ))}
      </VStack>

      <VStack spacing={4}>
        <Heading size="md">WISE-RA</Heading>
        {wiseraParagraphs.map((item: string) => (
          <Text key={item}>{item}</Text>
        ))}
      </VStack>

      <VStack spacing={4}>
        <Heading size="md">Wider IWW Structure</Heading>
        <Text>{widerIWW.intro}</Text>
        <CardGrid items={widerIWW.items} />
      </VStack>
    </Page>
  );
}

export const pageQuery = graphql`
  query StructurePageData {
    structureJson {
      nzParagraphs
      ausrocParagraphs
      wiseraParagraphs
      committees {
        heading
        body
      }
      widerIWW {
        intro
        items {
          heading
          body
        }
      }
    }
  }
`;
